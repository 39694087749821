import React, { useEffect } from "react";
import { IoAlarmOutline, IoLocationOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { EventGetItems } from "../../redux/createEvent/createEventAction/EventDetailAction";
import { CiCirclePlus } from "react-icons/ci";
import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../page/Footer";

const HomeDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, data, error } = useSelector((state) => state.eventItems);
  const accessToken = localStorage.getItem("access");

  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);

  const defaultImage = "https://via.placeholder.com/300x200.png?text=Default+Image";

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleGetTicketsClick = () => {
    if (accessToken) {
      navigate("/readyfortickets");
    } else {
      navigate("/login");
    }
  };

  const handleAddtoCalendar = () => {
    if (accessToken) {
      navigate("/addtoCalendar");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-grow p-5 flex justify-center">
        <div className="w-full max-w-5xl flex flex-col gap-6 font-poppins">
          {/* Event Name */}
          <div className="text-center">
            <h1 className="text-2xl font-bold">{data?.event_name}</h1>
          </div>

          {/* Venue and Time */}
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <IoLocationOutline size={25} />
              <p className="text-sm">Venue:</p>
              <p className="font-semibold text-base">{data?.venue?.venue_name}</p>
            </div>
            <div className="flex items-center gap-2">
              <IoAlarmOutline size={25} />
              <p className="text-sm">Time:</p>
              <p className="font-semibold text-base">{data?.start_time}</p>
            </div>
          </div>

          {/* Thumbnail Image */}
          {data?.thumbnail_path && (
            <div className="rounded-xl overflow-hidden">
              <img
                src={data?.thumbnail_path}
                alt="Event Thumbnail"
                className="w-full h-auto object-cover max-h-80"
              />
            </div>
          )}

          {/* Ticket and Calendar Options */}
          <div className="flex gap-4">
            <button
              className="w-full max-w-xs flex items-center justify-center gap-2 bg-red-500 text-white py-2 rounded-full"
              onClick={handleGetTicketsClick}
            >
              <span>Get Tickets</span>
            </button>
            <button 
              className="w-full max-w-xs flex items-center justify-center gap-2 bg-blue-500 text-white py-2 rounded-full" onClick={handleAddtoCalendar} >
              <span>Add to Calendar</span>
              <CiCirclePlus size={25} />
            </button>
          </div>

          {/* Event Details */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">Event Details</h2>
            {/* Enhanced Event Description */}
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div
                className="event-description text-gray-700 leading-relaxed space-y-4"
                dangerouslySetInnerHTML={{ __html: formatDescription(data?.description) }}
              />
            </div>
          </div>

          {/* Supporting Images */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6">
            <img
              src={data?.extra_image || defaultImage}
              alt="Supporting Image 1"
              className="rounded-md w-full h-auto max-w-[300px] max-h-[200px] object-cover"
            />
            <img
              src={data?.extra_image2 || defaultImage}
              alt="Supporting Image 2"
              className="rounded-md w-full h-auto max-w-[300px] max-h-[200px] object-cover"
            />
          </div>

          {/* Event Organizer */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold">Event Organizer</h2>
            <p>{data?.organizer_company || "Not provided"}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const formatDescription = (description) => {
  if (!description) return '';
  return description
    .replace(/\n/g, '<br/>') // Handle line breaks
    .replace(/•/g, '<ul class="list-disc ml-5"><li>') // Handle bullet points
    .replace(/<\/li>(?!<li>)/g, '</li></ul>'); // Close unordered lists properly
};

export default HomeDetail;
