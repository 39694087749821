// ProfileUpdateModal.jsx
import React from "react";
import Modal from "react-modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  IoPersonCircle,
  IoEyeOutline,
  IoEyeOffOutline,
  IoCallOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { MdOutlineMailOutline, MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../redux/auth/action/UserProfile"; // Adjust the import path as needed

Modal.setAppElement("#root"); // Set the root element for accessibility

const ProfileUpdateModal = ({ isOpen, onRequestClose, initialValues }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };

  const handleUpdate = (values, { setSubmitting }) => {
    dispatch(updateUserProfile(values));
    setSubmitting(false);
    onRequestClose(); // Close the modal after updating
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits")
      .required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="flex justify-center items-center p-6"
      overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-75"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
        <button 
          onClick={onRequestClose} 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <MdClose className="w-6 h-6" />
        </button>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          {({ isSubmitting }) => (
            <Form className="w-full">
              <p className="text-2xl font-semibold text-center mb-6">Update Profile</p>
              <div className="mb-4">
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium">First Name</label>
                <div className="relative">
                  <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                </div>
                <ErrorMessage name="first_name" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4">
                <label htmlFor="last_name" className="block mb-2 text-sm font-medium">Last Name</label>
                <div className="relative">
                  <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                </div>
                <ErrorMessage name="last_name" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                <div className="relative">
                  <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                </div>
                <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4">
                <label htmlFor="phone_number" className="block mb-2 text-sm font-medium">Phone Number</label>
                <div className="relative flex items-center">
                  <IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <span className="absolute left-10 top-1/2 transform -translate-y-1/2 text-gray-500">+44</span>
                  <Field
                    type="tel"
                    name="phone_number"
                    placeholder="Phone Number"
                    className="w-full p-3 pl-16 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    style={{ paddingLeft: '70px' }}
                  />
                </div>
                <ErrorMessage name="phone_number" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                <div className="relative">
                  <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                  >
                    {showPassword ? (
                      <IoEyeOffOutline className="w-5 h-5" />
                    ) : (
                      <IoEyeOutline className="w-5 h-5" />
                    )}
                  </button>
                </div>
                <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4">
                <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium">Confirm Password</label>
                <div className="relative">
                  <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    type={confirmPassword ? "text" : "password"}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                  >
                    {confirmPassword ? (
                      <IoEyeOffOutline className="w-5 h-5" />
                    ) : (
                      <IoEyeOutline className="w-5 h-5" />
                    )}
                  </button>
                </div>
                <ErrorMessage name="confirm_password" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="flex justify-center mb-4">
                <button type="submit" className="w-full p-3 bg-[#1399FF] text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-[#1399FF]">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProfileUpdateModal;
