// src/redux/reducers/ticketCategoryReducer.js

import { FETCH_TICKET_CATEGORIES_FAILURE, FETCH_TICKET_CATEGORIES_REQUEST, FETCH_TICKET_CATEGORIES_SUCCESS } from "../constant/GetTicketConstant";


const initialState = {
  custom_categories: [], // Add custom_categories
  predefined_categories: [], // Add predefined_categories
  loading: false,
  error: null,
};

const ticketCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_TICKET_CATEGORIES_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case FETCH_TICKET_CATEGORIES_SUCCESS:
          return {
              ...state,
              loading: false,
              custom_categories: action.payload.custom_categories,
              predefined_categories: action.payload.predefined_categories,
          };
      case FETCH_TICKET_CATEGORIES_FAILURE:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };
      default:
          return state;
  }
};

export default ticketCategoryReducer;
