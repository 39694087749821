// Profile.jsx
import React from "react";
//import Card from "../../component/CardComponent/Card";
import TicketCard from "../../component/CardComponent/TicketCard";

const PurchaseHistory = () => {
  const eventData = [
    {
      event_name: "asdfasdfadsf",
      organizer_company: "",
      category: 1,
      subcategory: 1,
      description: "fadsfasdf asdasdf ask asdf asdf",
      start_date: "2024-08-04",
      end_date: "2024-08-22",
      start_time: "06:00:00",
      end_time: "12:00:00",
      event_origin_country: "Nepal",
      ticket_price: "50.00",
      capacity: 2000,
      venue: {
        venue_name: "O2 Arena London",
        venue_capacity: 5000,
        flat_house_number: "Flat 57",
        street_address: "Latimer Road",
        city_town: null,
        county: null,
        post_code: "se17 2en",
        country: "United Kingdom"
      },
      share_link: null,
      thumbnail_path: "http://127.0.0.1:8000/media/event_thumbnail/Group_52_HAvzHge.png",
      extra_image: null,
      extra_image2: null
    }
  ];

  return (
    <div className="flex justify-center py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {eventData.map((EventData, index) => (
            <TicketCard EventData={EventData} index={index} key={index} />
          ))}
        </div>
    </div>
  );
};

export default PurchaseHistory;
