import React from "react";
import { Link } from "react-router-dom";
import './Card.css';

const Card = ({ EventData }) => {
  return (
    <Link to={`/HomeDetail/${EventData.event_id}`} key={EventData.event_id}>
      <div className="event-card">
        <img
          src={EventData?.thumbnail_path}
          alt={EventData.event_name}
          className="object-cover w-full h-[220px] rounded-t-lg"
        />
        <div className="p-4 flex flex-col justify-between flex-grow">
          <div>
            <h3 className="font-poppins text-xl font-bold text-center text-[#333] mb-2 truncate">
              {EventData.event_name}
            </h3>
            <p className="font-poppins text-sm text-center text-[#555] mb-2 line-clamp-2">
              {EventData.description}
            </p>
            <p className="font-poppins text-sm text-center text-[#333] mb-1">
              {EventData.start_date} at {EventData.start_time}
            </p>
            {EventData.venue && EventData.venue.venue_name && (
              <p className="font-poppins text-sm text-center text-[#333] mb-2">
                Venue: {EventData.venue.venue_name}
              </p>
            )}
          </div>
          <div className="price-container">
            <p className="font-poppins text-sm text-center font-medium text-[#707DFF] bg-[#f0f0ff] py-1 rounded-full">
              £ {EventData.ticket_price}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
