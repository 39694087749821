import apiClient from "../../../apiClient/apiClient"
 import { GET_CREATE_EVENT_ITEMS_FAILURE, GET_CREATE_EVENT_ITEMS_REQUEST, GET_CREATE_EVENT_ITEMS_SUCCESS } from "../createConstant/GetCreateConsant"
export const fetchGetItems=()=>async(dispatch)=>{
  dispatch({
      type:GET_CREATE_EVENT_ITEMS_REQUEST
  })
  try{
   const response=await apiClient.get("/events/")
   dispatch({
      type:GET_CREATE_EVENT_ITEMS_SUCCESS,
      payload:response.data
   })
  }
  catch(error){
      dispatch({
          type:GET_CREATE_EVENT_ITEMS_FAILURE,
          payload:error.message
      })
  
  }
} 