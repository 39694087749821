import React from 'react';
import { Link } from "react-router-dom";
import user from "../assets/user.png";
import logo from '../assets/eventfullsunday.png';
import LeftSection from '../component/Left/Left';

const EmailConfirmation = () => {
  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left Section */}
      <LeftSection 
        logo={logo}
        mainImage={user}
        mainText="Eventfull Sundays"
      />

      {/* Right Section: Email Confirmation Message */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
          <div className="text-center">
            <p className="text-2xl font-semibold mb-6">Email Confirmation</p>
            <p className="text-lg text-gray-700 mb-4">
              We have sent a confirmation link to your email. Please check your inbox and click the link to confirm your email address.
            </p>
            <p className="text-sm text-gray-600 mb-6">
              If you did not receive the email, please check your spam folder or try again.
            </p>
            <div className="mb-4">
              <Link 
                to="/login"
                className="block p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 w-full text-center"
              >
                Go to Login
              </Link>
            </div>
            <div className="text-sm text-center">
              <p>Still didn’t get the email? <Link to="/resend-confirmation" className="text-blue-600 underline">Resend Confirmation Email</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
