// Profile.jsx
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../redux/auth/action/UserProfile";
import { useNavigate } from "react-router-dom";
import userImage from "../../assets/user.png"; // Adjust the import path as needed
import ProfileUpdateModal from "./ProfileUpdateModal";

const Profile = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); // Using useNavigate hook

  // Fetch profile data
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  if (!profileState.profile) {
    return <div>Loading...</div>;
  }

  const {
    first_name,
    last_name,
    email,
    phone_number,
    profile_picture,
  } = profileState.profile || {};

  const initialValues = {
    first_name,
    last_name,
    email,
    phone_number,
    password: '',
    confirm_password: ''
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-start p-6 bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <div className="flex flex-col items-center">
          <img
            src={profile_picture || userImage}
            alt="User"
            className="w-24 h-24 rounded-full mb-4"
          />
          <h2 className="text-lg font-semibold">{`${first_name} ${last_name}`}</h2>
          <p className="text-gray-500 mb-2">{email}</p>
          <p className="text-gray-500 mb-4">{phone_number}</p>
          <div className="flex space-x-2">
            <button
              onClick={() => setIsModalOpen(true)}
              className="w-40 p-2 bg-[#1399FF] text-white rounded-full"
            >
              Update
            </button>
            <button
              onClick={() => navigate("/forgot-password")}
              className="w-40 p-2 bg-red-500 text-white rounded-full"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
      <ProfileUpdateModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        initialValues={initialValues}
      />
    </div>
  );
};

export default Profile;
