
import { PASSWORD_UPDATE_FAILURE, PASSWORD_UPDATE_REQUEST, PASSWORD_UPDATE_SUCCESS } from "../constant/ResetConstant";

const initialState = {
  loading: false,
  successMessage: '',
  errorMessage: '',
};

const passwordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        successMessage: '',
        errorMessage: '',
      };
    case PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        errorMessage: '',
      };
    case PASSWORD_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: '',
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default passwordResetReducer;