import { useEffect } from "react";

import Login from "./auth/Login/Login";
import { Route, BrowserRouter, Routes, Link } from "react-router-dom";
import Register from "./auth/Register/Register";
import ForgotPassword from "./auth/ForgotPassword/ForgotPassword";
import EmailConfirmation from "./auth/EmailConfirmation.jsx";
import Arts from "./page/Arts";
import Business from "./page/Business";
import Live from "./page/Live";
import Shows from "./page/Shows";
import Sports from "./page/Sports";
import Music from "./page/Music";
import SearchPage from "./page/SearchPage.jsx";
import AddtoCalendar from "./page/AddtoCalendar.js";
import Tech from "./page/Tech";
import Organizer from "./component/organizerComponent/Organizer";
import Home from "./page/Home";
import HomeDetail from "./component/HomeDetail/HomeDetail";
import Footer from "./page/Footer";
import Logout from "./auth/Logout/Logout";
import Dashboard from "./auth/dashboard/dashboard.jsx";
import Profile from "./auth/Profile/Profile";
import UserRegister from "./auth/Register/userRegister/UserRegister";
import PhoneRegister from "./auth/Register/phoneRegister/PhoneRegister";

import Code from "./auth/Register/phoneRegister/Code";
import OrganierRegister from "./auth/Register/OrganizerRegister/OrganizerRegister";
import TicketComponent from "./component/ticketComponent/TicketComponent";
// import TicketConfirmation from "./component/ticketComponent/TicketConfirmation";
import ReadyforTickets from "./component/ticketComponent/readyfortickets";
import TIcketLogin from "./auth/ticketLogin/TIcketLogin";
import TicketReview from "./component/ticketComponent/TicketReview";
import TicketCheckout from "./component/ticketComponent/TicketCheckout";
import TicketConfirmation from "./component/ticketComponent/ticketConfirmation/TicketConfirmation";
import Ticket from './component/ticketComponent/Ticket'
import PaymentComplete from "./component/ticketComponent/PaymentIntegration/PaymentComplete.js";
function App() {

  useEffect(() => {
    document.title = "Eventfull Sundays"
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgotPassword" element={<ForgotPassword />} />
        <Route exact path="/emailConfirmation" element={<EmailConfirmation />} />
        <Route exact path="/music" element={<Music />} />
        <Route exact path="/tech" element={<Tech />} />
        <Route exact path="/arts" element={<Arts />} />
        <Route exact path="/business" element={<Business />} />
        <Route exact path="/live" element={<Live />} />
        <Route exact path="/shows" element={<Shows />} />
        <Route exact path="/sports" element={<Sports />} />
        <Route exact path="/search" element={<SearchPage />} />
        <Route exact path="/organizer" element={<Organizer />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/footer" element={<Footer />} />
        <Route exact path="/homeDetail/:id" element={<HomeDetail />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path='/userRegister' element={<UserRegister/>} />
        <Route exact path='/phoneRegister' element={<PhoneRegister/>} />
        <Route exact path='/addtoCalendar' element={<AddtoCalendar/>} />
        <Route exact path='/paymentComplete' element={<PaymentComplete/>} />
        {/* <Route exact path='/ticket' element={<TicketComponent/>} /> */}
         <Route exact path='/readyfortickets' element={<ReadyforTickets/>} />
        <Route exact path='/ticketConfirmation' element={<TicketConfirmation/>} />
        <Route exact path='/organizerregister' element={<OrganierRegister/>} />
         <Route exact path='/ticketLogin' element={<TIcketLogin/>} />
         <Route exact path='/ticketReview' element={<TicketReview/>} />
         <Route exact path='/ticketCheckout' element={<TicketCheckout/>} />
          <Route exact path='/confirmation' element={<TicketConfirmation/>} />
          <Route exact path='/ticket' element={<Ticket/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
