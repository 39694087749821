import axios from "axios";
import { refreshAccessToken } from "../redux/auth/action/LoginAction";
import store from "../redux/store";

const apiClient = axios.create({
  baseURL: "https://api.eventfullsunday.co.uk/api/",
});

// for  access token
apiClient.interceptors.request.use(
  (config) => {
    const access = localStorage.getItem("access");
    if (access) {
      config.headers.Authorization = `Bearer ${access}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// for  token refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;
    if (status === 401) {
      await store.dispatch(refreshAccessToken());
      const newAccessToken = localStorage.getItem("access");
      if (newAccessToken) {
        error.config.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(error.config);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
