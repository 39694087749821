import { EDIT_EVENT_CONSTANT_FAILURE, EDIT_EVENT_CONSTANT_REQUEST, EDIT_EVENT_CONSTANT_SUCCESS } from "./EditEventConstant"


const initialState={
    loading:false,
    createEvent:null,
    error:null
}

const createEventReducer=(state=initialState,action)=>{
    switch(action.type){
        case EDIT_EVENT_CONSTANT_REQUEST:
            return{
                ...state,
                loading:true,
                error:null

            }
        case EDIT_EVENT_CONSTANT_SUCCESS:
            return{
                ...state,
                loading:false,
                createEvent:action.payload
            }
        case EDIT_EVENT_CONSTANT_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
        default:
            return state
    }
}
export default createEventReducer