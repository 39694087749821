import { GET_TICKET_REQUEST, GET_TICKET_SUCCESS, GET_TICKET_FAILURE } from '../constant/GetTicketConstant';

const initialState = {
    loading: false,
    ticketing: null, // Ensure this matches what you use in your action
    error: null,
};

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKET_REQUEST:
            return { ...state, loading: true };
        case GET_TICKET_SUCCESS:
            return { ...state, loading: false, ticketing: action.payload }; // Ensure payload is handled correctly
        case GET_TICKET_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default ticketReducer;
