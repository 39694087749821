import React, { useState } from "react";
import organizer from "../../../assets/event.png";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  IoPersonCircle,
  IoLockOpenOutline,
  IoEyeOutline,
  IoEyeOffOutline,
  IoCallOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerUser } from "../../../redux/auth/action/RegisterAction";
import CustomField from "../CustomField";

const OrganierRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const dispatch = useDispatch();

  //for showing the password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // for showing the confirm password
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(registerUser(values));
    setSubmitting(false);
  };

  const handleConfirmation = (choice) => {
    if (choice === "yes") {
      setConfirmSubmit(true);
      setSubmitted(false);
    } else {
      setConfirmSubmit(false);
      setSubmitted(false);
    }
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits")
      .required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleSubmit = () => {
    setSubmitted(true);
  };

  const handleButtonConfirmation = (response) => {
    if (response === "yes") {
      // Handle "yes" response
      setConfirmSubmit(true);
    } else {
      // Handle "no" response
      setConfirmSubmit(false);
    }
    setSubmitted(false); // Hide the confirmation message after handling
  };
  return (
    <div className="hide-scrollbar flex flex-col lg:flex-row gap-4 p-5 items-center place-items-center h-screen ">
      {/* Left Section */}
      <div className="p-5 flex justify-center items-center lg:w-1/2 hide-scrollbar ">
        <div>
          <p className="text-3xl w-[280px] font-semibold leading-10">
            Welcome to the{" "}
            <span className="text-[#707DFF]"> Organiser Side</span>
          </p>
          <img src={organizer} alt="Organizer" className="w-full  h-auto" />
        </div>
      </div>

      {/* Right Section: Register Form */}
      <div className="flex flex-col justify-center items-center lg:w-1/2 p-2 mt-20">
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirm_password: "",
            phone_number: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className="w-full max-w-md shadow-md p-2">
              <p className="text-xl font-semibold text-wider tracking-wider font-poppins text-center mb-4">
                Organizer Registration
              </p>
              <div className=" gap-4 mb-4">
                <div>
                  <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                    ORGANISER NAME
                  </p>
                  <div className="relative flex items-center">
                    <IoPersonCircle className="absolute left-3 text-gray-500 w-6 h-6" />

                    <CustomField
                      name="first_name"
                      placeholder="Joshuwa Mashon"
                      className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15"
                    />
                  </div>
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
              <div className="mb-4">
                <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                  BRIEF
                </p>
                <div className="relative flex items-center">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Lorem ipuslajsdf asdf jalsdj flasjdflajsd fja;lsdjf ;alksjdf ;lajsdlf;."
                    className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15"
                  />
                </div>

                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                  Email
                </p>
                <div className="relative flex items-center">
                  <MdOutlineMailOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                  <Field
                    type="email"
                    name="email"
                    placeholder="joshuwamason@gmail.com"
                    className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15"
                  />
                </div>

                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                  Phone Number
                </p>
                <div className="relative flex items-center">
                  <IoCallOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                  <Field
                    type="tel"
                    name="phone_number"
                    placeholder="+44 07534567890"
                    className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15"
                  />
                </div>
                <ErrorMessage
                  name="phone_number"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                  Password
                </p>
                <div className="relative flex items-center">
                  <IoLockClosedOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Iampassword123"
                    className="w-full text-sm p-3 rounded-full pl-10 font-poppins tracking-wide border border-[#0875E1] h-15"
                  />

                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-gray-500 cursor-pointer absolute right-3"
                  >
                    {showPassword ? (
                      <IoEyeOffOutline className="w-6 h-6" />
                    ) : (
                      <IoEyeOutline className="w-6 h-6" />
                    )}
                  </button>
                </div>

                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                  Confirm Password
                </p>
                <div className="relative flex items-center">
                  <IoLockClosedOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                  <Field
                    type={confirmPassword ? "text" : "password"}
                    name="confirm_password"
                    placeholder="Iampassword123"
                    className="w-full text-sm p-3  rounded-full pl-10 font-poppins tracking-wide border border-[#0875E1] h-15 flex justify-center "
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="text-gray-500 cursor-pointer absolute right-3"
                  >
                    {confirmPassword ? (
                      <IoEyeOffOutline className="w-6 h-6" />
                    ) : (
                      <IoEyeOutline className="w-6 h-6" />
                    )}
                  </button>
                </div>
                <ErrorMessage
                  name="confirm_password"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Submit button */}
              <div
                className="flex justify-center items-center mb-2"
                onClick={handleSubmit}
              >
                <Field
                  className="h-15 bg-[#0875E1] rounded-full cursor-pointer w-60 p-2 font-poppins text-base tracking-wide text-white"
                  type="submit"
                  value="REGISTER AS AN ORGANISER"
                />
              </div>

              {submitted && !confirmSubmit && (
                <div className="container mx-auto p-4 relative">
                  <div className="absolute inset-x-0 bottom-5 flex justify-center items-center z-20 p-4">
                    <div className="text-center bg-white shadow-md rounded h-[200px] flex justify-center flex-col p-2">
                      <p className="mb-2 font-poppins text-base flex justify-center w-[320px] leading-8 text-wider">
                      Your registration has been sent for review ! Once we verify you only then you will be able to do the event listing !
                      </p>
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => handleButtonConfirmation("yes")}
                          className="bg-[#0873dd] uppercase h-[40px] w-[100px] rounded-full text-white py-1 px-3 font-poppins text-base"
                        >
                          Okay
                        </button>
                   
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Login link */}
              <div className="flex justify-center items-center">
                <p className="underline  text-blue-500 underline-[#0875E1] cursor-pointer">
                  <Link to="/">Already a Oranizer ? </Link>
                  <Link to="/" className="text-blue-500">
                    Login Here
                  </Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default OrganierRegister;
