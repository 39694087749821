import apiClient from "../../../apiClient/apiClient";
import {
  GET_CREATE_EVENT_DETAILS_FAILURE,
  GET_CREATE_EVENT_DETAILS_REQUEST,
  GET_CREATE_EVENT_DETAILS_SUCCESS,
} from "../createConstant/EventDetailConstant";

export const EventGetItems = (id) => async (dispatch) => {
  dispatch({
    type: GET_CREATE_EVENT_DETAILS_REQUEST,
  });
  try {
   
   
    const response = await apiClient.get(`/events/${id}/`);
    dispatch({
      type: GET_CREATE_EVENT_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CREATE_EVENT_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};
