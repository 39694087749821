import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { EventGetItems } from "../../redux/createEvent/createEventAction/EventDetailAction";
import Navbar from "../Navbar/Navbar";
import Footer from "../../page/Footer";

const TicketReview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, data, error } = useSelector((state) => state.eventItems);
  console.log(data, "hello");
  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);
  return (
    <div className='gap-4 flex-col flex'>
       <Navbar/>
       <div className=' w-full flex justify-center flex-col'>
        <div className='flex justify-center flex-col ml-5 mr-5'>
        <div className="flex justify-between items-center">
        <p className="font-poppins text-base tracking-wider text-wider">
          Ticket selection
        </p>
        <p className="font-poppins text-base tracking-wider text-wider">
          Review and payment
        </p>
        <p className="font-poppins text-base tracking-wider text-wider">
          Confirmation
        </p>
      </div>
      <div className='w-full flex justify-center p-2'>
      <div className='shadow-md w-[40vw] p-2'>
      <div className="gap-2 flex flex-col mb-2">
        <p className="font-poppins text-base tracking-wider text-wider underline flex justify-center">
          Review your purchase details
        </p>
        <p className=" font-poppins text-base tracking-wider text-wider">{data?.event_name}</p>
        <p className=" font-poppins text-base tracking-wider text-wider">Venue:{data?.venue}</p>
        <p className=" font-poppins text-base tracking-wider text-wider">Time:{data?.start_time}</p>
        <p className=" font-poppins text-base tracking-wider text-wider">Address:{data?.address}</p>
        <p className="font-poppins text-base tracking-wider text-wider">Postcode:{data?.post}</p>
      </div>
      <hr />
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-4 leading-8'>
          <p className='font-poppins text-base tracking-wider '>Price:</p>
          <p className='font-poppins text-base tracking-wider'> £ </p>
        </div>
        <div className='flex items-center gap-4 leading-8'>
          <p className='font-poppins text-base tracking-wider'>Quantity</p>
          <p className='font-poppins text-base tracking-wider'>2</p>
        </div>
        <div className='flex gap-4 leading-8 items-center'>
          <p className='font-poppins text-base tracking-wider'>Sub-Total:</p>
          <p className='font-poppins text-base tracking-wider'> £ 80</p>
        </div>
        <div className='flex gap-4 leading-8 items-center'>
          <p className='font-poppins text-base tracking-wider'>Fee:</p>
          <p className='font-poppins text-base tracking-wider'>£ 0</p>
        </div>
        <div className='flex gap-4 leading-8 items-center'>
          <p className='font-poppins text-base tracking-wider'>Total:</p>
          <p className='font-poppins text-base tracking-wider'>£ 80</p>
        </div>
      </div>
      <div>
      </div>
      <Link to='/ticketCheckout'>
      <div className=' flex justify-center '>
         <p className='bg-[#FF6347] w-[400px] text-base p-2 rounded-full flex justify-center text-white  font-poppins text-wider tracking-wider'>Proceed to Checkout</p>
      </div>
      </Link>
    
      </div>
      </div>
        </div>
    
    </div>
    <Footer/>
    </div>
   
  );
};

export default TicketReview;
