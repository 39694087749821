import React from 'react';
import { Field, useFormikContext } from 'formik';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CustomField = ({ name, placeholder, className }) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    const { value } = e.target;
    const capitalizedValue = capitalizeFirstLetter(value);
    setFieldValue(name, capitalizedValue);
  };

  return (
    <Field
      type="text"
      name={name}
      placeholder={placeholder}
      className={className}
      onChange={handleChange}
    />
  );
};

export default CustomField;
