import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetItems } from "../redux/createEvent/createEventAction/GetEventAction";
import Navbar from "../component/Navbar/Navbar"; // Assuming the Navbar is in this path
import Footer from "../page/Footer"; // Assuming the Footer is in this path

const SearchPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Accessing the event data from Redux state
  const categoryState = useSelector((state) => state.getEvent);
  const { data: events, loading, error } = categoryState;

  const [filteredEvents, setFilteredEvents] = useState([]);

  // Extracting the search query from the URL
  const query = new URLSearchParams(location.search).get("q");

  useEffect(() => {
    // Dispatch the action to fetch events when the component mounts
    dispatch(fetchGetItems());
  }, [dispatch]);

  useEffect(() => {
    // Filter events based on the search query
    if (query && events?.length > 0) {
      const lowercasedQuery = query.toLowerCase();
      const filtered = events.filter((event) =>
        event.event_name.toLowerCase().includes(lowercasedQuery) ||
        event.description?.toLowerCase().includes(lowercasedQuery) ||
        event.event_origin_country?.toLowerCase().includes(lowercasedQuery) ||
        event.organizer_company?.toLowerCase().includes(lowercasedQuery) ||
        event.venue.venue_name?.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(events || []);
    }
  }, [query, events]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <Navbar />
      <div className="flex justify-center py-8" style={{ minHeight: "70vh" }}>
        <div className="w-[85vw] flex flex-col gap-6">
          <h2 className="font-poppins text-2xl tracking-wide font-semibold text-center text-[#333] mb-6">
            Search Results for "{query}"
          </h2>

          {filteredEvents.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {filteredEvents.map((event) => (
                <Link
                  to={`/HomeDetail/${event.event_id}`}
                  key={event.event_id}
                  className="shadow-md bg-white rounded-md hover:shadow-lg transition-shadow duration-300"
                >
                  <img
                    src={event.thumbnail_path || "default-image-path.jpg"}
                    alt={event.event_name}
                    className="object-cover w-full rounded-t-md h-[150px]"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold mb-2">
                      {event.event_name}
                    </h3>
                    <p className="text-sm text-gray-600 mb-2">
                      {event.description}
                    </p>
                    <p className="text-sm text-gray-500">
                      Venue: {event.venue.venue_name}, {event.venue.country}
                    </p>
                    <p className="text-sm text-gray-500">
                      Organizer: {event.organizer_company || "N/A"}
                    </p>
                    <p className="text-sm text-gray-500">
                      Country: {event.event_origin_country}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">
              No events found matching your search.
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;
