import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../component/Navbar/Navbar";
import TrendingEvent from "../component/trendingEvent/TrendingEvent";
import Footer from "./Footer";
import { fetchGetItems } from "../redux/createEvent/createEventAction/GetEventAction";

const Home = () => {
  const [filterText, setFilterText] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const dispatch = useDispatch();

  const categoryState = useSelector((state) => state.getEvent);
  const { data, loading, error } = categoryState;

  useEffect(() => {
    dispatch(fetchGetItems());
  }, [dispatch]);

  // Filter events based on filterText
  useEffect(() => {
    if (data && filterText) {
      const filterTextLower = filterText.toLowerCase();
      const filtered = data.filter((event) =>
        event.event_name.toLowerCase().includes(filterTextLower)
      );
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(data || []);
    }
  }, [filterText, data]);

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-col gap-4" style={{ backgroundColor: "#f5f5f5" }}>
      <Navbar filterText={filterText} handleFilterChange={handleFilterChange} />
      <TrendingEvent data={filteredEvents} />

      <Footer />
    </div>
  );
};

export default Home;
