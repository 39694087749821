// LeftSection.jsx
import React from 'react';

const Left = ({ logo, mainImage, mainText }) => {
    return (
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-[#f5f5f5] p-6 lg:p-0">
        <div className="flex flex-col items-center gap-6 text-center">
          <img src={logo} alt="Logo" className="w-20 h-20" />
          <p className="text-2xl font-semibold md:text-3xl" style={{ fontSize: '36px', fontFamily: 'Poppins, sans-serif', lineHeight: '168.4%' }}>
            Welcome to the<br />
            <span className="text-[#1399FF]">{mainText}</span>
          </p>
          <img src={mainImage} alt="Main" className="w-full max-w-md h-74 object-cover" />
        </div>
      </div>
    );
  };

export default Left;
