import React from "react";
import { QRCodeCanvas } from "qrcode.react"; // Import the QRCodeCanvas component

const Confirmation = ({ ticketDetails }) => {
  if (!ticketDetails) {
    return <p>Error: Ticket details are not available.</p>; // Handle missing ticket details
  }

  // Create a string or JSON object with the information you want to encode in the QR code
  const qrData = JSON.stringify({
    event_name: ticketDetails.event_name,
    venue: ticketDetails.venue,
    time: ticketDetails.time,
    address: ticketDetails.address,
    event_id: ticketDetails.event,
    ticket_quantity: ticketDetails.ticket_quantity,
    sub_total: ticketDetails.sub_total,
    total: ticketDetails.total,
  });

  console.log("Confirmation Data: ", qrData);

  return (
    <div className="shadow-lg bg-gray-100 p-6 rounded-lg">
      <h2 className="font-poppins text-lg md:text-xl font-semibold text-center text-gray-800">
        Thank you for your Payment
      </h2>
      <p className="font-poppins text-base text-center text-gray-700 mt-2">
        Your purchase reference number: <strong>SAJ2341234</strong>
      </p>
      <div className="flex flex-col items-center mt-6">
        <p className="font-poppins text-base text-gray-700 mb-4">
          Your QR Ticket:
        </p>
        <QRCodeCanvas
          value={qrData} // Pass the data to be encoded in the QR code
          size={256} // Set the size of the QR code
          bgColor={"#ffffff"} // Background color
          fgColor={"#000000"} // Foreground color
          level={"H"} // Error correction level (H is the highest)
          includeMargin={true} // Include margin around the QR code
        />
      </div>
      <div className="mt-6 text-center">
        <p className="font-poppins text-sm md:text-base text-gray-700 leading-7 max-w-2xl mx-auto">
          Thank you for choosing us and purchasing your ticket through our
          website. Please check your email for your recent purchased ticket information & QR code. You
          will need to show QR code at entrance for entry.
        </p>
      </div>
    </div>
  );
};

export default Confirmation;