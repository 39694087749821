import React, { useEffect, useState } from "react";
import eventfullsunday from "../../assets/eventfullsunday.png";
import { useDispatch, useSelector } from "react-redux";
import { EventGetItems } from "../../redux/createEvent/createEventAction/EventDetailAction";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { loginUser } from "../../redux/auth/action/LoginAction";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoPersonCircle,
} from "react-icons/io5";
import * as Yup from "yup";
const TicketCheckout = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, data, error } = useSelector((state) => state.eventItems);
  console.log(data, "hello");
  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);

  // showing the password
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  // set up initial value
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(loginUser(values));
    setSubmitting(false);
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-between p-2 place-content-center">
        {/* <div className="bg-[#0B1B34] w-[700px] h-full flex flex-col gap-4 ">
          <div className="flex items-center gap-2">
            <IoMdArrowRoundBack />
            <img
              src={eventfullsunday}
              alt="image"
              className="w-[25px] h-[25px]"
            />
            <p className="font-poppins text-base tracking-wider leading-8 text-white">
              eventfullsundays.co.uk
            </p>
          </div>

          <p className="font-poppins text-base tracking-wider leading-8 text-white">
            Pay eventfullsundays.co.uk
          </p>
          <p className="text-white font-poppins text-base tracking-wider">
            {data?.ticket_price}
          </p>
          <p className="text-white font-poppins text-base tracking-wider">
            {data?.event_name}
          </p>
          <div>
            <p className="text-white font-poppins text-base tracking-wider">
              Qty.<span>2</span>
            </p>
            <p className="font-poppins text-base tracking-wider text-white">
              {data?.ticket_price}
            </p>
          </div>

          <div>
            <p className="font-poppins text-base tracking-wider text-white">
              Service Fee
            </p>
            <p className="font-poppins text-base tracking-wider text-white">
              £ 0.00
            </p>
          </div>
          <p className="font-poppins text-base tracking-wider text-white">
            Qty 1
          </p>
        </div> */}

        <div className="bg-[#0B1B34] w-[650px] min-h-screen flex flex-col gap-4 place-content-center ">
          <div className="flex place-content-center justify-center flex-col left-[100px] relative gap-4">
            <div className="flex items-center gap-2 ">
              <IoMdArrowRoundBack color="white" />
              <img
                src={eventfullsunday}
                alt="image"
                className="w-[25px] h-[25px]"
              />
              <p className="font-poppins text-base tracking-wider leading-8 text-white">
                eventfullsundays.co.uk
              </p>
            </div>

            <p className="font-poppins text-base tracking-wider leading-8 text-white">
              Pay eventfullsundays.co.uk
            </p>
            <p className="text-white font-poppins text-xl tracking-wider font-semibold ">
              £ {data?.ticket_price}
            </p>
            <p className="text-white font-poppins text-base tracking-wider">
              {data?.event_name}
            </p>
            <p className="font-poppins text-base tracking-wider text-white relative flex justify-end right-[200px]">
              £ {data?.ticket_price}
            </p>
            <div className="flex justify-between ">
       
                <div className='flex'>
                  <p className="text-white font-poppins text-base tracking-wider ">
                    Qty.
                  </p>
                  <p className="text-white font-poppins text-base tracking-wider ">
                    2
                  </p>
                </div>
                <p className="font-poppins text-base tracking-wider text-white relative right-[210px]">
                  {" "}
                  £ 40.00
                </p>
          
            </div>

            <div className="flex justify-between ">
              <p className="font-poppins text-base tracking-wider text-white">
                Service Fee
              </p>
              <div>
                <p className="font-poppins text-base tracking-wider text-white relative right-[220px]">
                  £ 0.00
                </p>
              </div>
            </div>
            <p className="font-poppins text-base tracking-wider text-white">
              Qty 1
            </p>
          </div>
        </div>

        <div className='flex justify-center '>
          <div className="flex  p-5 flex-col justify-center items-center place-content-center ">
            <Formik
              initialValues={initialValues}
              onSubmit={handleLogin}
              validationSchema={validationSchema}
            >
              {({
                handleSubmit,
                touched,
                handleBlur,
                handleChange,
                errors,
                values,
              }) => (
                <Form
                  validationschema={validationSchema}
                  onSubmit={handleSubmit}
                  className="w-full md:w-[60vw] xl:w-[40vw] lg:w-[35vw]"
                >
                  <p className="text-xl md:text-3xl font-semibold text-wider tracking-wider font-poppins text-center mb-4 2xl:text-4xl">
                    Contact Information
                  </p>
                  <div className="mb-4 gap-2 grid grid-cols-1 ">
                    <p className="font-[400px]   text-base text-wider tracking-wider font-poppins 2xl:text-3xl">
                      EMAIL
                    </p>
                    <div className="relative flex items-center ">
                      <IoPersonCircle className="absolute left-4 text-gray-500 w-7 h-7" />
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full md:text-lg text-sm p-3 pl-14 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-12
                   "
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Login button */}
                  <Link to="/confirmation">
                    <div className="flex justify-center items-center mb-2">
                      <button
                        type="submit"
                        className="h-10 w-[400px] bg-[#FF6347] rounded-full cursor-pointer  p-2 font-poppins text-base tracking-wide text-white"
                      >
                        Complete Order
                      </button>
                    </div>
                  </Link>
                  <div className="flex justify-center gap-2">
                    <p className="font-poppins text-base tracking-wider text-wider">
                      Powered by stripe{" "}
                    </p>
                    <p>|</p>
                    <p className="font-poppins text-base tracking-wider text-wider">
                      Terms
                    </p>
                    <p>|</p>
                    <p className="font-poppins text-base tracking-wider text-wider">
                      Privacy
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCheckout;
