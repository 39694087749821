// UserProfile.jsx
import apiClient from "../../../apiClient/apiClient";
import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
} from "../constant/ProfileConstant";

// Action to get user profile
export const getUserProfile = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_REQUEST });
  try {
    const accessToken = localStorage.getItem("access");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await apiClient.get("/user-profile/", config);
    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAILURE,
      payload:
        error.response?.data?.error ||
        "An error occurred while fetching the profile",
    });
  }
};

// Action to update user profile
export const updateUserProfile = (formData) => async (dispatch) => {
  dispatch({ type: GET_PROFILE_REQUEST }); // You can create a new action type if needed

  try {
    const token = localStorage.getItem("access");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await apiClient.put(
      "/user-profile/",
      formData,
      config
    );

    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: response.data,
    });

    // Optionally, you can navigate the user or show a success message here
    window.location.href = "/Dashboard"; 
  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAILURE,
      payload: error.message,
    });
  }
};
