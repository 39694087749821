import axios from "axios";

import apiClient from "../../../apiClient/apiClient";

export const passwordResetRequest = () => ({ type: "PASSWORD_RESET_REQUEST" });
export const passwordResetSuccess = (message) => ({
  type: "PASSWORD_RESET_SUCCESS",
  payload: message,
});
export const passwordResetFailure = (error) => ({
  type: "PASSWORD_RESET_FAILURE",
  payload: error,
});

//For reset password
export const resetPassword = (email) => async (dispatch) => {
  dispatch(passwordResetRequest());
  try {
    const token = localStorage.getItem('access');
    console.log(token, 'getting the token');
    
    const response = await apiClient.post('/password-reset/', { email }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  
    const { uidb64, token: resetToken } = response.data;

    dispatch(passwordResetSuccess(response.data.message));

    window.location.href = `/password-reset-confirm/`;
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'An error occurred.';
    dispatch(passwordResetFailure(errorMessage));
    console.error(errorMessage);
    alert(errorMessage);
  }
};