import { combineReducers } from "redux";
import userReducer from "./auth/reducer/LoginReducer";
import registerReducer from "./auth/reducer/RegisterReducer";
import passwordReducer from "./auth/reducer/ForgotPasswordReducer";
import profileReducer from "./auth/reducer/ProfileReducer";
import organizationReducer from "./auth/reducer/OrganizationProfileReducer";
import passwordResetReducer from "./auth/reducer/ResetReducer";
import CreateCategoryReducer from "./createEvent/CreateCategoryReducer/CreateCategoryReducer";
import subCategoryReducer from "./createEvent/CreateSubCategoryReducer/CreateSubCategoryReducer";
import getCreateEventReducer from "./createEvent/createEventReducer/GetCreateEventReducer";
import { eventDetailsReducer } from "./createEvent/createEventReducer/EventDetailReducer";
import venueReducer from "./venue/VenuReducer/VenueReducer";
import getVenueReducer, {
  GetVenueReducer,
} from "./venue/VenuReducer/GetVenueRedcuer";
import venueDetailReducer from "./venue/VenuReducer/VenueDetailReducer";
import venueUpdateReducer from "./venue/VenuReducer/VenueUpdataReducer";
import createEventReducer from "./createEvent/EditEvent/EditEventReducer";
import ticketReducer from "./ticket/reducer/GetTicketReducer";
import GetTicketItemsReducer from "./ticket/reducer/GetTicketItemsReducer";
import ticketCategoryReducer from "./ticket/reducer/GetTicketItemsReducer";

const rootReducer = combineReducers({
  user: userReducer,
  passwordReset: passwordReducer,
  passwordResetDetail: passwordResetReducer,
  register: registerReducer,
  profile: profileReducer,
  organization: organizationReducer,
  category: CreateCategoryReducer,
  subcategory: subCategoryReducer,
  getEvent: getCreateEventReducer,
  eventItems: eventDetailsReducer,
  venueRegister: venueReducer,
  getVenue: getVenueReducer,
  venueDetail: venueDetailReducer,
  venueEdit: venueUpdateReducer,
  update: createEventReducer,
  ticket: ticketReducer,
  ticketCategories: ticketCategoryReducer,
});

export default rootReducer;
