import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { IoCallOutline, IoPersonCircle } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { getProfile } from "../../redux/auth/action/Profile";
import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../page/Footer";

const Profile = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);

  // Open modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Fetch profile data
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  if (!profileState.profile) {
    return <div>Loading...</div>;
  }

  const {
    first_name,
    last_name,
    company_name,
    business_email,
    business_phone,
    flat_house_number,
    street_address,
    city_town,
    company_logo,
    country,
    post_code,
  } = profileState.profile || {};

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  return (
    <div className="w-full h-full">
      <Navbar />
      <div className='w-full flex justify-center'>
        
      <div className="w-[95vw]   p-3  font-poppins text-base">
        <div className='flex flex-col gap-4'>
          <p className="font-semibold text-xl font-poppins leading-7 tracking-wider text-wider">
            First Name:{" "}
            <span className="text-base font-medium">{first_name}</span>
          </p>
          <p className="font-semibold text-xl font-poppins leading-7 tracking-wider text-wider">
            Last Name:{" "}
            <span className="text-base font-medium">{last_name}</span>
          </p>
        </div>
      </div>
      </div>
     
      <Footer/>
    </div>
  );
};

export default Profile;
