import React from "react";
import event from "../assets/eventfullsunday.png";
import { Field, Form, Formik } from "formik";
import { IoPersonCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { MdOutlineMail } from "react-icons/md";
import image from "../assets/light.png";
import { FaFacebook, FaInstagram, FaWhatsapp, FaGooglePlay, FaApple } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const Footer = () => {
  const initialValues = {
    email: "",
    name: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    name: Yup.string().required("Name is required"),
  });

  const handleFooter = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  return (
    <div className="relative bg-white">
      <img
        src={image}
        alt=""
        className="absolute top-0 left-0 w-full h-full object-cover brightness-50 xl:max-h-[300px] 2xl:max-h-full"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-white opacity-70"></div>
      <div className="relative z-50 flex flex-col p-8">
        <div className="container mx-auto flex flex-col md:flex-row justify-between gap-10">
          {/* Left Section */}
          <div className="flex flex-col gap-4">
            <p className="text-xl font-bold text-black font-poppins text-center md:text-left">
              Eventful Sundays
            </p>
            <p className="text-base tracking-wider font-light font-poppins text-center md:text-left">
              Your go-to place for community events
            </p>
            <div className="flex justify-center md:justify-start">
              <img
                src={event}
                alt="Organizer"
                className="max-w-[150px] max-h-[150px]"
              />
            </div>
            <p className="text-xs md:text-left text-center text-black font-poppins">
              &copy; 2024 Eventful Sundays. All rights reserved.
            </p>
          </div>

          {/* Middle Section: Links */}
          <div className="text-base font-poppins tracking-wider text-black flex flex-col gap-2 items-center md:items-start">
            <Link to="/about" className="hover:underline">About us</Link>
            <Link to="/terms" className="hover:underline">Terms and conditions</Link>
            <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
            <Link to="/contact" className="hover:underline">Contact us</Link>
          </div>

          {/* Right Section: Footer Form */}
          <div className="flex flex-col items-center md:items-start">
            <Formik
              initialValues={initialValues}
              onSubmit={handleFooter}
              validationSchema={validationSchema}
            >
              {() => (
                <Form className="w-full md:w-auto shadow-lg p-4 rounded-lg bg-white">
                  <p className="font-bold tracking-wider font-poppins text-center mb-4 text-black text-lg">
                    Subscribe to our Newsletter
                  </p>
                  <div className="mb-4">
                    <label className="block font-medium text-base text-wider tracking-wider font-poppins">
                      Your Name
                    </label>
                    <div className="relative flex items-center mb-2">
                      <IoPersonCircle className="absolute left-4 text-gray-500 w-5 h-5" />
                      <Field
                        type="text"
                        name="name"
                        placeholder="John Doe"
                        className="w-full md:text-lg text-sm p-2 pl-10 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-10 placeholder:text-xs"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block font-medium text-base text-wider tracking-wider font-poppins">
                      Email Address
                    </label>
                    <div className="relative flex items-center">
                      <MdOutlineMail className="absolute left-4 text-gray-500 w-5 h-5" />
                      <Field
                        type="email"
                        name="email"
                        placeholder="johndoe@gmail.com"
                        className="w-full md:text-lg text-sm p-2 pl-10 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-10 placeholder:text-xs"
                      />
                    </div>
                  </div>

                  <div className="flex justify-center md:justify-start mb-2">
                    <Field
                      className="h-8 w-32 md:w-32 md:text-sm bg-[#0875E1] rounded-full cursor-pointer font-poppins text-base tracking-wide text-white"
                      type="submit"
                      value="Subscribe"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {/* Social Media & App Links */}
          <div className="flex flex-col gap-6">
            <div className="text-black gap-4">
              <p className="font-semibold mb-4 text-center md:text-left">Follow us on Social Media</p>
              <div className="flex justify-center md:justify-start gap-4">
                <FaFacebook size={30} />
                <FaInstagram size={30} className="text-[#fd334e]" />
                <BsTwitterX size={30} className="text-[#000000]" />
                <FaWhatsapp size={30} className="text-[#25d366]" />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-black font-poppins text-base tracking-wider font-semibold text-center md:text-left">
                Download our app
              </p>
              <div className="flex justify-center md:justify-start gap-4">
                <FaGooglePlay size={30} />
                <FaApple size={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;