import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../../page/Footer";
import Qr from "../../../assets/Qr.png";
import { Link } from "react-router-dom";

const TicketConfirmation = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex justify-center flex-grow">
        <div className="w-[90vw] flex flex-col gap-4">
          {/* Progress Timeline */}
          <div className="flex justify-between items-center">
            <p className="font-poppins text-base tracking-wider">
              Ticket selection
            </p>
            <p className="font-poppins text-base tracking-wider">
              Review and payment
            </p>
            <p className="font-poppins text-base tracking-wider">
              Confirmation
            </p>
          </div>

          {/* Confirmation Message */}
          <div className="shadow-md bg-[#F5F5F5] flex flex-col gap-4 p-6">
            <p className="font-poppins text-base tracking-wider leading-8 font-semibold text-center">
              Thank you for your Payment
            </p>
            <p className="font-poppins text-base tracking-wider leading-8 text-center">
              Your purchase reference number: SAJ2341234
            </p>

            {/* QR Code Section */}
            <div className="flex justify-center flex-col items-center">
              <p>Your QR Ticket:</p>
              <img src={Qr} alt="QR Code" className="w-[316px] h-[316px]" />
            </div>

            {/* Thank You Message */}
            <div className="flex justify-center items-center w-full">
              <p className="font-poppins text-base tracking-wider leading-8 text-center">
                Thank you for choosing us and purchasing your ticket through our
                website. We truly appreciate your trust and business. Please
                check your email for your recent purchased ticket information &
                QR code. You will need to show QR code at entrance for entry.
              </p>
            </div>
          </div>

          {/* Go to Tickets Link and Contact Information */}
          <div className="flex flex-col justify-center w-full">
            <Link to="/ticket">
              <p className="text-[#0875E1] font-poppins font-light underline text-center mb-2">
                Go to your Tickets
              </p>
            </Link>
            <div className="flex justify-center">
              <p className="font-normal bg-[#FF6347] p-2 rounded-md text-white text-center w-[863px]">
                If you have any questions, please contact:
                sales@eventfullsundays.co.uk
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TicketConfirmation;
