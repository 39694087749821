import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import event from "../../assets/eventfullsunday.png";
import { IoMenu, IoPersonCircleSharp, IoSearch, IoLocationSharp } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../../redux/auth/action/Profile";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const categories = ['music', 'tech', 'sports', 'business', 'arts', 'shows'];
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get the profile state from Redux
  const profileState = useSelector((state) => state.profile);
  const firstName = profileState?.profile?.first_name;

  // Fetch the profile data when the component mounts
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getProfile());
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [dispatch]);

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  // Handle search form submission
  const handleSearchSubmit = (values) => {
    navigate(`/search?q=${encodeURIComponent(values.search)}`);
  };

  // Render categories for the navbar
  const renderCategories = () => (
    <div className="xl:flex flex-wrap justify-center bg-white p-2 shadow-sm hidden md:flex">
      {categories.map((category) => (
        <NavLink
          key={category}
          to={`/${category}`}
          className="text-gray-500 p-1 mx-2 rounded-full border-2 border-[#1399FF] text-xs font-poppins"
        >
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </NavLink>
      ))}
      <button
        onClick={() => window.open('https://maps.google.com', '_blank')}
        className="text-gray-500 p-1 mx-2 rounded-full border-2 border-[#1399FF] text-xs font-poppins flex items-center"
      >
        <IoLocationSharp className="mr-1" /> Search Nearby Location
      </button>
    </div>
  );

  // Render the profile dropdown menu
  const renderProfileDropdown = () => (
    <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-20">
      <Link to="/dashboard" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
        Dashboard
      </Link>
      <Link to="/logout" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
        Logout
      </Link>
    </div>
  );

  // Render profile links
  const renderProfileLinks = () => (
    <div className="flex items-center space-x-4">

    <a 
          href="https://events.eventfullsunday.co.uk/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="font-poppins text-base text-black font-semibold"
        >
          BLOGS
        </a>

      <div className="relative">
        <IoPersonCircleSharp size={25} onClick={toggleDropdown} className="cursor-pointer text-black" />
        {dropdownVisible && renderProfileDropdown()}
      </div>
      {firstName && (
        <Link to="/dashboard" className="ml-1">
          <p className="font-poppins text-base text-black font-medium">{firstName}</p>
        </Link>
      )}
    </div>
  );

  // Render authentication links
  const renderAuthLinks = () => (
    <div className="flex items-center space-x-4">
      <Link to="/organizer" className="font-poppins text-base text-black font-semibold">
        ORGANISER?
      </Link>
      <a 
          href="https://events.eventfullsunday.co.uk/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="font-poppins text-base text-black font-semibold"
        >
          BLOGS
        </a>
      <Link to="/register" className="font-poppins text-base text-black font-semibold">
        SIGNUP
      </Link>
      <Link to="/login" className="font-poppins text-base text-black font-semibold">
        LOGIN
      </Link>
    </div>
  );

  // Render mobile menu for smaller screens
  const renderMobileMenu = () => (
    <div className="md:hidden bg-white absolute top-16 left-0 w-full p-4 shadow-md z-20">
      {renderSearchForm()}
      <div className="flex flex-col items-center gap-4 mt-4">
        {categories.map((category) => (
          <NavLink
            key={category}
            to={`/${category}`}
            className="text-gray-500 p-1 w-full text-center rounded-full border-2 border-[#1399FF] text-xs font-poppins"
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </NavLink>
        ))}
        <button
          onClick={() => window.open('https://maps.google.com', '_blank')}
          className="text-gray-500 p-1 w-full text-center rounded-full border-2 border-[#1399FF] text-xs font-poppins flex items-center justify-center"
        >
          <IoLocationSharp className="mr-1" /> Search Nearby Location
        </button>
      </div>
      <div className="flex flex-col items-center gap-4 mt-4">
        {localStorage.getItem("access") ? renderProfileLinks() : renderAuthLinks()}
      </div>
    </div>
  );

  // Render the search form in the navbar
  const renderSearchForm = () => (
    <Formik initialValues={{ search: "" }} onSubmit={handleSearchSubmit}>
      {({ getFieldProps }) => (
        <Form className="flex items-center w-full max-w-2xl relative">
          <IoSearch className="absolute left-4 text-gray-500 w-5 h-5" />
          <Field
            type="text"
            {...getFieldProps("search")}
            placeholder="Search events, communities, browse #hashtags"
            className="w-full p-2 pl-12 border-2 border-[#1399FF] rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </Form>
      )}
    </Formik>
  );

  return (
    <nav className="relative bg-white shadow-md">
      <div className="container mx-auto flex justify-between items-center p-4">
        <Link to="/">
          <img src={event} className="w-20 h-20 rounded-md" alt="Event" />
        </Link>
        <div className="flex-grow flex flex-col items-center">
          <div className="hidden md:flex items-center justify-center w-full max-w-2xl">
            {renderSearchForm()}
          </div>
          {renderCategories()}
        </div>
        <div className="md:hidden">
          <IoMenu size={30} onClick={toggleMenu} className="text-black" />
        </div>
        <div className="hidden md:flex items-center gap-6">
          {localStorage.getItem("access") ? renderProfileLinks() : renderAuthLinks()}
        </div>
      </div>
      {isMenuOpen && renderMobileMenu()}
    </nav>
  );
};

export default Navbar;
