import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../component/ticketComponent/PaymentIntegration/PaymentForm';

// Load your publishable key
const stripePromise = loadStripe('pk_test_51PoKMcRtZG1tTL5YTQtW1RQvIw6vv6mvxk8TcTCwuC1xtYHhwoAi4OyC2wizVx3rE44vKGmgKo2cR6BpmOl1LB8000S4XN3x2N');

function Add() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); // New state to hold error messages

  const handlePayment = async (paymentMethodId) => {
    try {
      const response = await fetch('http://localhost:3002/process-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paymentMethodId }),
      });

      const data = await response.json();

      if (data.success) {
        setPaymentStatus('success');
      } else {
        setPaymentStatus('error');
        setErrorMessage(data.error); // Store the error message
      }
    } catch (error) {
      setPaymentStatus('error');
      setErrorMessage('Network error: Unable to process payment'); // Handle network errors
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-bold text-center mb-6">Complete Your Payment</h1>

        {paymentStatus === 'success' && (
          <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-md">
            Payment successful!
          </div>
        )}

        {paymentStatus === 'error' && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
            Payment failed! {errorMessage && <p>{errorMessage}</p>}
          </div>
        )}

        <Elements stripe={stripePromise}>
          <PaymentForm handlePayment={handlePayment} />
        </Elements>

        {paymentStatus && (
          <button
            onClick={() => window.location.reload()}
            className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            Make Another Payment
          </button>
        )}
      </div>
    </div>
  );
}

export default Add;
