import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, REFRESH_FAILURE, REFRESH_SUCCESS } from "../constant/LoginConstant";

const initialState = {
  isAuthenticated: false,
  accessToken: localStorage.getItem('access') || null,
  refreshToken: localStorage.getItem('refresh') || null,

  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('access', action.payload.access);
      localStorage.setItem('refresh', action.payload.refresh);
     
      return {
        ...state,
        access: action.payload.access,
        refresh: action.payload.refresh,
    
        error: null,
      };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload };
    case LOGOUT:
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        error: null,
      };
    case REFRESH_SUCCESS:
      return { ...state, access: action.payload };
    case REFRESH_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default userReducer;
