import { VENUE_FAILURE, VENUE_REQUEST, VENUE_SUCCESS } from "../VenueConstant/VenueConstant"

const initialState={
    loading:false,
    venue:null,
    error:null
}

const venueReducer=(state=initialState,action)=>{
    switch(action.type){
        case VENUE_REQUEST:
            return {
                ...state,
                loading:true,
                error:null
            }
        case VENUE_SUCCESS:
            return{
                ...state,
                loading:false,
                venue:action.payload,
            }
        case VENUE_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
        default:
            return state
    }

}
export default venueReducer