import { CREATE_CATEGORY_CONSTANT_FAILURE, CREATE_CATEGORY_CONSTANT_REQUEST, CREATE_CATEGORY_CONSTANT_SUCCESS } from "../CreateCategoryConstant/CreateCategoryConstant"


const initialState={
    token:null,
    profile:null,
    loading:false,
    error:null
}

const CreateCategoryReducer=(state=initialState,action)=>{
  switch(action.type){
    case CREATE_CATEGORY_CONSTANT_REQUEST:
        return{
            ...state,
            loading:true,
            error:null
        }
    case CREATE_CATEGORY_CONSTANT_SUCCESS:
        return{
            ...state,
            loading:false,
            profile:action.payload
        }
    case CREATE_CATEGORY_CONSTANT_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.payload
        }
    default:
        return state
  }
}
export default CreateCategoryReducer