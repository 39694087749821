import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../constant/LoginConstant";
import apiClient from "../../../apiClient/apiClient";

//for logout success
export const loginoutSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
});

// Action creators
export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

//condition for login failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

//condition for logout
export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");

  return { type: LOGOUT };
};


export const loginUser = (values, redirectTo = "/") => async (dispatch) => {
  dispatch(loginRequest());

  try {
    const response = await apiClient.post("login/", values);
    const { access, refresh } = response.data;
    if (access && refresh) {
      localStorage.setItem("access", access);
      localStorage.setItem("refresh", refresh);

      dispatch(loginSuccess({ access, refresh }));
      toast.success("Login successful");

      setTimeout(() => {
        window.location.href = redirectTo; // Redirect to the provided URL
      }, 2000);
    } else {
      throw new Error("Invalid response from the server");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { data } = error.response;

      if (data.email) {
        toast.error("No active account with this email");
      } else if (data.password) {
        toast.error("Invalid password");
      } else {
        toast.error(data.detail || "An error occurred. Please try again later.");
      }
    } else {
      toast.error("An error occurred. Please try again later.");
    }

    dispatch(loginFailure("Invalid email or password"));
  }
};


// refresh token
export const refreshAccessToken = () => async (dispatch) => {
  try {
    const refresh = localStorage.getItem("refresh");
    if (!refresh) throw new Error("No refresh token available");
    const response = await apiClient.post("refresh-token/", { refresh });
    const { access } = response.data;
    if (access) {
      localStorage.setItem("access", access);
      dispatch(
        loginSuccess({
          access,
          refresh,
        })
      );
    } else {
      throw new Error("Invalid response from the server");
    }
  } catch (error) {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    dispatch(logout());
    // window.location.href = "/login";
  }
};

//logout section
export const logoutUser = () => async (dispatch) => {
  try {
    const refresh = localStorage.getItem("refresh");
    if (refresh) {
      const response = await apiClient.post("logout/", { refresh });
      console.log(response);
      if (response.status === 205) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        dispatch(logout());
        window.location.href = "/";
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } else {
      console.log("No refresh token found.");
    }
  } catch (error) {
    console.error("Logout error:", error);
   
  }
};
