import { CREATE_SUBCATEGORY_CONSTANT_FAILURE, CREATE_SUBCATEGORY_CONSTANT_REQUEST, CREATE_SUBCATEGORY_CONSTANT_SUCCESS } from "../CreateSubCategoryConstant/CreateSubCategoryConstant";

const initialState = {
    loading: false,
    dataone: [],
    error: null,
  };
  
  const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_SUBCATEGORY_CONSTANT_REQUEST:
        return { ...state, loading: true, error: null };
      case CREATE_SUBCATEGORY_CONSTANT_SUCCESS:
        return { ...state, loading: false, dataone: action.payload };
      case CREATE_SUBCATEGORY_CONSTANT_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default subCategoryReducer;