import React, { useState } from "react";
import event from "../../../assets/event.png";
import eventfullsunday from "../../../assets/eventfullsunday.png";
import Navbar from "../../../component/Navbar/Navbar";
import Footer from "../../../page/Footer";
import { FaGoogle, FaPhoneAlt } from "react-icons/fa";
import Google from "../../../assets/Google.png";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IoPersonCircle } from "react-icons/io5";
const UserRegister = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <div>
      <Navbar />
      <div className="w-full ">
        <div className="flex ">
          <div className=" xl:w-[698px] flex flex-col gap-4 p-2">
            <p className="font-semibold text-3xl font-poppins w-[300px]">
              Welcome to the{" "}
              <span className="text-[#707DFF]">Event full sundays</span>
            </p>
            <img
              src={event}
              alt="image"
              className="w-[451px] h-[399px] rounded-md"
            />
          </div>
          <div className="text-base font-poppins shadow-md p-2  tracking-wider flex flex-col gap-4 ">
            <div className="flex justify-center">
              <img
                src={eventfullsunday}
                alt="eventfull"
                className="w-[106px] h-[106px]"
              />
            </div>

            <p className="text-base font-poppins  flex justify-center tracking-wider text-wider font-semibold">
              Registration as a user
            </p>
            <div className="font-medium flex justify-center h-[40px] rounded-full items-center gap-4 text-base tracking-wider font-poppins  border border-[#0875E1] ">
              <p>Register with Google</p>
              <img src={Google} alt="google" className="w-[15px] h-[15px]" />
            </div>
            <Link to='/phoneRegister'>
            <div className="relative">
              <div
                className="font-medium text-base border h-[40px] rounded-full items-center gap-4 border-[#0875E1] flex justify-center tracking-wider font-poppins cursor-pointer"
             
              >
                <p>Register with phone number</p>
                <FaPhoneAlt />
              </div>
              {showTooltip && (
                <div className="absolute -top-[60px] left-1/2 transform -translate-x-1/2 mb-2 w-max p-2 text-xs text-white bg-[#0875E1] rounded-lg ">
                  <Formik
                    initialValues={{ phoneNumber: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.phoneNumber) {
                        errors.phoneNumber = "Required";
                      } else if (!/^\d+$/.test(values.phoneNumber)) {
                        errors.phoneNumber = "Invalid phone number";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      // Handle form submission
                      console.log(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      handleSubmit,
                      touched,
                      handleBlur,
                      handleChange,
                      errors,
                      values,
                    }) => (
                      <Form className="w-full md:w-[60vw] xl:w-[30vw] lg:w-[30vw] p-2 ">
                        <p className="text-base md:text-base  font-semibold text-wider tracking-wider font-poppins text-center mb-4 2xl:text-4xl">
                          Enter your Number
                        </p>
                        <div className="mb-4 gap-2 grid grid-cols-1">
                          <p className="font-[400px] text-base text-wider tracking-wider font-poppins 2xl:text-3xl">
                            PHONE NUMBER
                          </p>
                          <div className="relative flex items-center">
                            <FaPhoneAlt className="absolute left-4 text-gray-500 w-7 h-7" />
                            <Field
                              name="phoneNumber"
                              placeholder="Enter your phone number"
                              className="w-full text-black md:text-lg text-sm p-3 pl-14 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-15"
                            />
                          </div>
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>

                        {/* Submit button */}
                        <div className="flex justify-center items-center mb-2">
                          <button
                            type="submit"
                            className="h-10 outline outline-white p-2 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>

            </Link>
           
            <div className="font-medium text-base gap-4 flex flex-col  tracking-wider font-poppins ">
              <Link to="/register">
                <p className="flex justify-center  border border-[#0875E1]  h-[40px] rounded-full items-center">
                  Manual Registration
                </p>
              </Link>

              <p className="flex justify-center ">or</p>
              <Link to='/organizerregister'>
              <p className="flex justify-center  border border-[#0875E1]   h-[40px] rounded-full items-center">
                Organiser Registration
              </p>
              </Link>
            
            </div>
            <p className="flex justify-center ">Getting Started !</p>
            <p className="text-center h-[60px] flex justify-center w-[360px]">
              By Tapping Login, you agree to our Terms. Learn how we process
              your data in our Privacy Policy
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UserRegister;
