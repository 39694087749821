import React, { useState } from "react";
import user from "../../assets/user.png";
import logo from '../../assets/eventfullsunday.png';

import { Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoEyeOutline,
  IoEyeOffOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/auth/action/LoginAction";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LeftSection from '../../component/Left/Left';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(loginUser(values))
      .catch(() => toast.error("Failed to log in")); 
    setSubmitting(false);
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left Section */}
      <LeftSection 
        logo={logo}
        mainImage={user}
        mainText="Eventfull Sundays"
      />

      {/* Right Section: Login Form */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
            {({ handleSubmit, handleBlur, handleChange, values }) => (
              <Form onSubmit={handleSubmit} className="w-full">
                <p className="text-2xl font-semibold text-center mb-6">Login</p>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Email/Phone Number</label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      placeholder="nepalieventorg@gmail.com"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                  </div>
                </div>
                <div className="mb-4">
                 <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                  <div className="relative">
                    <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      placeholder="••••••••"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      {!showPassword ? <IoEyeOffOutline className="w-5 h-5" /> : <IoEyeOutline className="w-5 h-5" />}
                    </button>
                  </div>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <label className="flex items-center">
                    <Field type="checkbox" name="remember" className="mr-2" />
                    Remember Me
                  </label>
                  <Link to="/ForgotPassword" className="text-blue-600 text-sm underline">Forgot Password?</Link>
                </div>
                <div className="flex justify-center mb-4">
                  <button type="submit" className="w-full p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600">Submit</button>
                </div>
                <div className="text-center">
                  <p className="text-sm">
                    Don’t have an account? <Link to="/register" className="text-blue-600 underline">Register Here</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
      </div>
    </div>
  );
};

export default Login;