import React from "react";

const ProgressTimeline = ({ currentStep, handleStepChange }) => {
  return (
    <div className="flex justify-between items-center mb-8">
      <div
        className={`flex-1 text-center cursor-pointer ${currentStep === 1 ? "text-blue-600 font-semibold" : "text-gray-400"}`}
        onClick={() => handleStepChange(1)} // Allow navigation to step 1 (Ticket Selection)
      >
        <p>Ticket Selection</p>
        <div className={`h-1 mt-2 ${currentStep === 1 ? "bg-blue-600" : "bg-gray-300"} mx-auto w-full`} />
      </div>

      <div
        className={`flex-1 text-center cursor-pointer ${currentStep === 2 ? "text-blue-600 font-semibold" : "text-gray-400"}`}
        onClick={() => currentStep > 2 && handleStepChange(2)} // Allow going back to step 2 (Review and Payment)
      >
        <p>Review and Payment</p>
        <div className={`h-1 mt-2 ${currentStep === 2 ? "bg-blue-600" : "bg-gray-300"} mx-auto w-full`} />
      </div>

      <div
        className={`flex-1 text-center ${currentStep === 3 ? "text-blue-600 font-semibold" : "text-gray-400"}`}
      >
        <p>Confirmation</p>
        <div className={`h-1 mt-2 ${currentStep === 3 ? "bg-blue-600" : "bg-gray-300"} mx-auto w-full`} />
      </div>
    </div>
  );
};

export default ProgressTimeline;
