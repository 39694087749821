import React, { useState } from "react";
import event from "../../assets/event.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoLockOpenOutline,
  IoEyeOutline,
  IoEyeOffOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUser } from "../../redux/auth/action/LoginAction";

const TIcketLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  // showing the password
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  // set up initial value
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false 
  };


  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),

    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required')
 
  });

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(loginUser(values));
    setSubmitting(false);
  };

  return (
    <div className="flex  flex-col xl:flex-row lg:flex-row  items-center h-screen   gap-4 p-5 hide-scrollbar">
      {/* Left Section */}
      <div className="p-5  flex justify-center items-center lg:w-1/2 2xl:justify-center 2xl:align-middle">
        <div className="gap-4 flex-col flex">
          <p className="text-2xl w-[220px] font-semibold leading-7 md:text-3xl md:w-[300px]">
          Please Login to Buy
          </p>
          <span className="text-[#707DFF] font-poppins text-2xl font-semibold text-wider tracking-wider">
          THE TICKETS
          </span>
          <img src={event} alt="Organizer" className="w-full  h-auto" />
        </div>
      </div>

      {/* Right Section: Login Form */}
      <div className="flex  p-5 flex-col justify-center items-center lg:w-1/2">
        <Formik initialValues={initialValues} onSubmit={handleLogin}  validationSchema={validationSchema}
         >
          {({
            handleSubmit,
            touched,
            handleBlur,
            handleChange,
            errors,
            values,
          }) => (
            <Form
            validationschema={validationSchema}
              onSubmit={handleSubmit}
              className="w-full md:w-[60vw] xl:w-[40vw] lg:w-[35vw]"
            >
              <p className="text-xl md:text-3xl font-semibold text-wider tracking-wider font-poppins text-center mb-4 2xl:text-4xl">
                Login
              </p>
              <div className="mb-4 gap-2 grid grid-cols-1 ">
                <p className="font-[400px]  text-base text-wider tracking-wider font-poppins 2xl:text-3xl">
                  EMAIL/PHONE NUMBER
                </p>
                <div className="relative flex items-center ">
                  <IoPersonCircle className="absolute left-4 text-gray-500 w-7 h-7" />
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full md:text-lg text-sm p-3 pl-14 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-15 
                   "
                  />
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4 grid grid-cols-1 gap-2">
                <p className="font-[400px]  text-base text-wider tracking-wider font-poppins 2xl:text-3xl">
                  PASSWORD
                </p>
                <div className="relative flex items-center ">
                  <IoLockClosedOutline className="absolute left-4 text-gray-500 w-7 h-7" />
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    placeholder="iampassword"
                    className="w-full md:text-lg  text-base p-3 pl-14 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-15"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-gray-500 cursor-pointer absolute right-4"
                  >
                    {!showPassword ? (
                      <IoEyeOffOutline className="w-7 h-7" />
                    ) : (
                      <IoEyeOutline className="w-7 h-7" />
                    )}
                  </button>
               
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Remember me & Forgot password */}
              <div className="w-full grid grid-cols-2  mb-4">
                <div className="w-full flex items-center gap-2">
                  <Field
                    type="checkbox"
                  
              id="rememberMe"
              name="rememberMe"
                  />
                  <label
                    htmlFor="remember"
                    className="font-poppins md:text-base xl:font-semibold text-xs  md:font-semibold 2xl:text-2xl"
                  >
                    Remember Me
                  </label>
                </div>
                {/* Forgot password */}
                <div className="flex justify-end">
                  <p className="font-poppins text-xs md:text-base md:font-semibold underline  text-[#32CD32] cursor-pointe 2xl:text-2xl">
                    <Link to="/ForgotPassword">Forgot Password?</Link>
                  </p>
                </div>
              </div>
              {/* Login button */}
              <Link to='/ticketReview'>
              <div className="flex justify-center items-center mb-2">
                <button
                  type="submit"
                  className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white"
                >
                  Submit
                </button>
              </div>
              </Link>
           

              <div className="flex justify-center items-center text-sm">
                <p className="underline text-blue-500  underline-[#0875E1] cursor-pointer 2xl:text-2xl 2xl:font-poppins">
                 <Link to='/register'>Don’t have an account?</Link> 
                  <Link to="/register" className="text-blue-500">
                    Register Here
                  </Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
     <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
};

export default TIcketLogin;
