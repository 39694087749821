// Dashboard.jsx
import React, { useState } from "react";
import { IoPersonCircle } from "react-icons/io5";
import { FaHistory, FaBell, FaLock, FaCog, FaTachometerAlt } from "react-icons/fa";
import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../page/Footer"; // Adjust the import path as needed
import Profile from "./Profile"; // Adjust the import path as needed
import PurchaseHistory from "./PurchaseHistory";
import Updates from "./Updates";
import PrivacyPolicy from "./PrivacyPolicy";
import Settings from "./Settings";

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState("profile");

  const renderContent = () => {
    switch (selectedSection) {
      case "dashboard":
        return (
          <div className="w-full bg-white p-6 rounded-lg shadow-lg">
            Welcome to the Dashboard
          </div>
        );
      case "profile":
        return <Profile />;
      case "purchase-history":
        return (
          <PurchaseHistory />
        );
      case "updates":
        return (
          <Updates />
        );
      case "privacy-policy":
        return (
          <PrivacyPolicy />
        );
      case "settings":
        return (
          <Settings />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <div className="flex flex-grow bg-gray-100">
        {/* Sidebar */}
        <div className="w-full sm:w-1/4 lg:w-1/5 bg-white shadow-lg p-8 h-3/4 sm:h-3/4 lg:h-4/5">

          <div className="flex flex-col justify-between h-full">
            <div>
            <button
                onClick={() => setSelectedSection("dashboard")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${
                  selectedSection === "dashboard"
                    ? "text-white bg-blue-500 rounded-lg"
                    : "text-gray-700 hover:bg-gray-200 rounded-lg"
                }`}
              >
                <FaTachometerAlt className="w-6 h-6 mr-4" />
                <span>Dashboard</span>
              </button>
              <button
                onClick={() => setSelectedSection("profile")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${
                  selectedSection === "profile"
                    ? "text-white bg-blue-500 rounded-lg"
                    : "text-gray-700 hover:bg-gray-200 rounded-lg"
                }`}
              >
                <IoPersonCircle className="w-6 h-6 mr-4" />
                <span>Profile</span>
              </button>
              <button
                onClick={() => setSelectedSection("purchase-history")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${
                  selectedSection === "purchase-history"
                    ? "text-white bg-blue-500 rounded-lg"
                    : "text-gray-700 hover:bg-gray-200 rounded-lg"
                }`}
              >
                <FaHistory className="w-6 h-6 mr-4" />
                <span>Purchase History</span>
              </button>
              <button
                onClick={() => setSelectedSection("updates")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${
                  selectedSection === "updates"
                    ? "text-white bg-blue-500 rounded-lg"
                    : "text-gray-700 hover:bg-gray-200 rounded-lg"
                }`}
              >
                <FaBell className="w-6 h-6 mr-4" />
                <span>Updates</span>
              </button>
              <button
                onClick={() => setSelectedSection("privacy-policy")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${
                  selectedSection === "privacy-policy"
                    ? "text-white bg-blue-500 rounded-lg"
                    : "text-gray-700 hover:bg-gray-200 rounded-lg"
                }`}
              >
                <FaLock className="w-6 h-6 mr-4" />
                <span>Privacy Policy</span>
              </button>
              <button
                onClick={() => setSelectedSection("settings")}
                className={`flex items-center p-4 text-lg transition duration-300 ease-in-out w-full ${
                  selectedSection === "settings"
                    ? "text-white bg-blue-500 rounded-lg"
                    : "text-gray-700 hover:bg-gray-200 rounded-lg"
                }`}
              >
                <FaCog className="w-6 h-6 mr-4" />
                <span>Settings</span>
              </button>
            </div>
            <div className="text-center mt-8">
              <p className="text-sm text-gray-600">
                &copy; 2022 eventfullsundays.co.uk
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full sm:w-3/4 lg:w-4/5 p-8">{renderContent()}</div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
