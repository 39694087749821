import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../../page/Footer";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EventGetItems } from "../../redux/createEvent/createEventAction/EventDetailAction";
import ProgressTimeline from "./ProgressTimeline";
import TicketSelection from "./TicketSelection";
import ReviewAndPayment from "./ReviewAndPayment";
import Confirmation from "./Confirmation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51PokKeAYVDhgEidXzPKzYHEOhoHUHR1z1zYz3EM32kUtYLC9jU5LyvRBlaDwpx1F8L9LlS13lZPpPoGz13dr0ZFf00190UEQ7u");

const ReadyforTickets = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const [ticketDetails, setTicketDetails] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, data, error } = useSelector((state) => state.eventItems);

  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);

  const handleGetTickets = (details) => {
    setTicketDetails(details);
    setCurrentStep(2);
  };

  const handleIncrement = () => {
    setTicketQuantity((prev) => prev + 1);
    setTicketDetails((prev) => ({
      ...prev,
      ticket_quantity: ticketQuantity + 1,
      sub_total: (ticketQuantity + 1) * prev.price,
      total: (ticketQuantity + 1) * prev.price,
    }));
  };

  const handleDecrement = () => {
    if (ticketQuantity > 1) {
      setTicketQuantity((prev) => prev - 1);
      setTicketDetails((prev) => ({
        ...prev,
        ticket_quantity: ticketQuantity - 1,
        sub_total: (ticketQuantity - 1) * prev.price,
        total: (ticketQuantity - 1) * prev.price,
      }));
    }
  };

  const handleConfirmPayment = () => {
    setCurrentStep(3);
  };

  // Function to handle step changes, but only allow backward movement
  const handleStepChange = (step) => {
    if (step < currentStep) {
      setCurrentStep(step); // Only update the current step if it's a backward move
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex flex-col items-center flex-grow px-4">
        <div className="w-full max-w-4xl flex flex-col gap-8 py-8">
          <ProgressTimeline currentStep={currentStep} handleStepChange={handleStepChange} />

          {currentStep === 1 && (
            <TicketSelection
              data={data}
              ticketQuantity={ticketQuantity}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
              totalPrice={ticketQuantity * (data?.ticket_price || 0)}
              handleGetTickets={handleGetTickets}
            />
          )}

          {currentStep === 2 && (
            <Elements stripe={stripePromise}>
              <ReviewAndPayment
                ticketDetails={ticketDetails}
                handleConfirmPayment={handleConfirmPayment}
              />
            </Elements>
          )}

          {currentStep === 3 && (
            <Confirmation ticketDetails={ticketDetails} />  
          )}

          <div className="flex flex-col items-center mt-6">
            {currentStep === 3 && (
              <>
                <Link to="/ticket">
                  <p className="text-blue-600 font-poppins text-sm md:text-base underline mb-4">
                    Go to your Tickets
                  </p>
                </Link>
                <p className="font-poppins text-sm md:text-base text-white bg-red-500 p-3 rounded-lg text-center max-w-2xl">
                  If you have any questions, please contact: sales@eventfullsundays.co.uk
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReadyforTickets;
