import React from "react";
import userImage from "../../assets/user.png";
import logo from '../../assets/eventfullsunday.png';
import { Link, useNavigate } from "react-router-dom";
import { IoCallOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Organizer = ({ eventId }) => {
  const navigate = useNavigate();

  const handleGoogleRegister = () => {
    // Placeholder for Google registration implementation
    console.log("Google registration clicked");
  };

  const handlePhoneRegister = () => {
    toast.info("Coming soon!");
  };

  const handleManualRegister = () => {
    navigate('/register');
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left Section */}
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2 bg-[#f5f5f5] p-6 lg:p-0">
        <img src={logo} alt="Logo" className="w-20 h-20 mb-4" />
        <p className="text-3xl font-semibold md:text-5xl text-center mb-4" style={{ fontSize: '36px', fontFamily: 'Poppins, sans-serif', lineHeight: '168.4%' }}>
          Welcome to the <br />
          <span className="text-[#1399FF]">Eventful Sundays</span>
        </p>
        <img src={userImage} alt="Organizer" className="w-full max-w-md h-74 object-cover" />
      </div>

      {/* Right Section */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">
        <div className="flex justify-center items-center w-full h-full">
          <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-sm" style={{ height: '700px' }}>
            <div className="flex flex-col justify-center h-full">
              <p className="text-xl font-semibold text-center mb-6">Registration as a user</p>
              <button
                className="w-full p-3 mb-4 bg-white border border-[#1399FF] rounded-full text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#1399FF] flex items-center justify-between"
                onClick={handleGoogleRegister}
              >
                Register with Google <span className="text-[#1399FF]"><img src="https://image.similarpng.com/very-thumbnail/2020/06/Logo-google-icon-PNG.png" alt="Google Logo" className="w-6 h-6" /></span>
              </button>
              <button
                className="w-full p-3 mb-4 bg-white border border-[#1399FF] rounded-full text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#1399FF] flex items-center justify-between"
                onClick={handlePhoneRegister}
              >
                Register with phone number <span className="text-[#1399FF]"><IoCallOutline className="w-6 h-6" /></span>
              </button>
              <button
                className="w-full p-3 mb-4 bg-white border border-[#1399FF] rounded-full text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                onClick={handleManualRegister}
              >
                Manual Registration
              </button>
              <p className="text-center mb-4">or</p>
              <button className="w-full p-3 mb-4 bg-[#1399FF] text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-[#1399FF]">
                Organizer Registration
              </button>
              <p className="text-center font-semibold mb-4">Getting Started!</p>
              <p className="text-center text-sm">
                By Tapping Login, you agree to our <Link to="/terms" className="text-[#1399FF] underline">Terms</Link>. Learn how we process your data in our <Link to="/privacy" className="text-[#1399FF] underline">Privacy Policy</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Organizer;
