import React from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { logoutUser } from "../../redux/auth/action/LoginAction";

const Logout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="shadow-md  flex flex-col items-center justify-center p-4 bg-white hover:shadow-xl rounded-md">
        <p className="text-center mb-4 font-poppins text-base">
          Do you want to logout?
        </p>
        <div className="flex gap-4">
          <Link to="/organizer">
            <p className="cursor-pointer outline  w-[100px] h-[40px] rounded-full items-center flex justify-center text-center font-poppins text-base hover:outline-[#0875e1]">
              No
            </p>
          </Link>

          <p
            className="cursor-pointer text-blue-500 outline w-[100px] h-[40px]  items-center text-center flex justify-center rounded-full font-poppins text-base  hover:outline-[#0875e1]"
            onClick={handleLogout}
          >
            Yes
          </p>
        </div>
      </div>
    </div>
  );
};

export default Logout;
