import React, { useState } from "react";
import mobile from "../../../assets/mobile.png";
import eventfullsunday from "../../../assets/eventfullsunday.png";
import { ErrorMessage, Field, Formik, Form } from "formik"; // Corrected import
import { IoLockClosedOutline, IoPersonCircle } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const PhoneRegister = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <div className="flex gap-4 justify-between p-5">
      <div>
        <p className="font-poppins text-2xl w-[300px] tracking-wider text-wider ">
          Register through <span className="text-[#1399FF]">Mobile Number</span>
        </p>
        <img src={mobile} alt="mobile" />
      </div>
      <div className="shadow-md p-2 flex flex-col gap-4">
        <p className="font-poppins text-base leading-7 tracking-wider text-wider flex justify-center">
          Registration through Number
        </p>
        <div className="flex justify-center">
          <img
            src={eventfullsunday}
            alt="eventfullsunday"
            className="w-[106px] h-[106px] flex justify-center"
          />
        </div>
        <Formik
          initialValues={{ phoneNumber: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.phoneNumber) {
              errors.phoneNumber = "Required";
            } else if (!/^\d+$/.test(values.phoneNumber)) {
              errors.phoneNumber = "Invalid phone number";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // Handle form submission
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            touched,
            handleBlur,
            handleChange,
            errors,
            values,
          }) => (
            <Form className="w-full md:w-[60vw] xl:w-[40vw] lg:w-[35vw]">
              <div className="mb-4 gap-2 grid grid-cols-1 ">
                <p className="font-[400px]  text-base text-wider tracking-wider font-poppins 2xl:text-3xl">
                  Name
                </p>
                <div className="relative flex items-center ">
                  <IoPersonCircle className="absolute left-4 text-gray-500 w-7 h-7" />
                  <Field
                    name="email"
                    placeholder="Enter your full name here."
                    className="w-full md:text-lg text-sm p-3 pl-14 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-12
                   "
                  />
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4 grid grid-cols-1 gap-2">
                <p className="font-[400px]  text-base text-wider tracking-wider font-poppins 2xl:text-3xl">
                  Number
                </p>
                <div className="flex gap-4">
                  <Field
                    name="password"
                    placeholder="GB +44"
                    className="w-full xl:w-[100px] md:text-lg  text-base p-3  font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-12"
                  />
                  <div className="relative flex items-center ">
                    <Field
                      name="password"
                      placeholder="Enter your number"
                      className="w-full md:text-lg  text-base p-3 pl-14 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-12"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mb-4 ">
                <p className="flex justify-center items-center text-center w-[300px] font-poppins text-base">
                  We will text you a code to verify your number. Data rates may
                  apply!
                </p>
              </div>
              {/* Login button */}
              <Link to="/code">
                <div className="flex justify-center items-center mb-2">
                  <button
                    type="submit"
                    className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white"
                  >
                    Next
                  </button>
                </div>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PhoneRegister;
