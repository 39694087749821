import React from "react";
import Card from "../CardComponent/Card"; // Make sure to import the Card component

const TrendingEvent = ({ data }) => {
  // Reverse sort the events to show the latest first
  const sortedData = [...data].reverse();

  return (
    <div className="flex justify-center py-8">
      <div className="w-[85vw] flex flex-col gap-6">
        <h2 className="font-poppins text-2xl tracking-wide font-semibold text-center text-[#333] mb-6">
          Trending Events
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {sortedData.map((EventData, index) => (
            <Card EventData={EventData} index={index} key={EventData.event_id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingEvent;
