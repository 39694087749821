import {
  GET_CREATE_EVENT_DETAILS_FAILURE,
  GET_CREATE_EVENT_DETAILS_REQUEST,
  GET_CREATE_EVENT_DETAILS_SUCCESS,
} from "../createConstant/EventDetailConstant";
const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const eventDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREATE_EVENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CREATE_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_CREATE_EVENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
