import React from "react";
import { IoAlarmOutline, IoLocationOutline } from "react-icons/io5";

const TicketSelection = ({
  data,
  ticketQuantity,
  handleIncrement,
  handleDecrement,
  totalPrice,
  handleGetTickets,
}) => {
  const ticketPrice = parseFloat(data?.ticket_price || 0);
  const defaultImage = "https://via.placeholder.com/300x200.png?text=Default+Image";

  const eventDetails = {
    event: data?.event_id,
    event_name: data?.event_name,
    venue: data?.venue?.venue_name,
    address: data?.venue?.address,
    time: `${data?.start_time} - ${data?.end_time}`,
    ticket: "basic",
    price: ticketPrice,
    ticket_quantity: ticketQuantity,
    sub_total: totalPrice,
    total: totalPrice, // Assuming no additional fees or discounts
  };

  console.log(data);

  return (
    <>
      {data && (
        <div className="shadow-lg bg-gray-100 p-6 rounded-lg">
          <h2 className="font-poppins text-lg md:text-xl font-semibold text-center text-gray-800">
            {data?.event_name}
          </h2>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center gap-2">
              <IoLocationOutline size={25} />
              <p className="text-sm">Venue:</p>
              <p className="font-semibold text-base">{data?.venue?.venue_name}</p>
            </div>
            <div className="flex items-center gap-2">
              <IoAlarmOutline size={25} />
              <p className="text-sm">Time:</p>
              <p className="font-semibold text-base">
                {data?.start_time} - {data?.end_time}
              </p>
            </div>
          </div>
          {data?.thumbnail_path && (
            <div className="rounded-xl overflow-hidden mt-4">
              <img
                src={data?.thumbnail_path || defaultImage}
                alt="Event Thumbnail"
                className="w-full h-auto object-cover max-h-80"
              />
            </div>
          )}
          <br />
          {/* Event Details */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">Event Details</h2>
            {/* Enhanced Event Description */}
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div
                className="event-description text-gray-700 leading-relaxed space-y-4"
                dangerouslySetInnerHTML={{ __html: formatDescription(data?.description) }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="shadow-lg bg-gray-100 p-6 rounded-lg mt-6">
        <h2 className="font-poppins text-lg md:text-xl font-semibold text-center text-gray-800">
          Select Your Tickets
        </h2>
        <div className="flex flex-col items-center mt-6">
          {/* Ticket Price */}
          <p className="font-poppins text-lg text-gray-700 mb-4">
            Price per Ticket: ${ticketPrice.toFixed(2)}
          </p>

          {/* Ticket Quantity Selector */}
          <div className="flex items-center justify-center gap-4 mb-4">
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              onClick={handleDecrement}
            >
              -
            </button>
            <span className="font-poppins text-lg text-gray-800">{ticketQuantity}</span>
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>

          {/* Total Price */}
          <p className="font-poppins text-lg text-gray-700 mb-6">
            Total Price: ${totalPrice.toFixed(2)}
          </p>

          {/* Get Tickets Button */}
          <button
            className="bg-blue-600 text-white py-2 px-4 rounded-lg"
            onClick={() => handleGetTickets(eventDetails)}
          >
            Get Tickets
          </button>
        </div>
      </div>
    </>
  );
};

const formatDescription = (description) => {
  if (!description) return '';
  return description
    .replace(/\n/g, '<br/>') // Handle line breaks
    .replace(/•/g, '<ul class="list-disc ml-5"><li>') // Handle bullet points
    .replace(/<\/li>(?!<li>)/g, '</li></ul>'); // Close unordered lists properly
};

export default TicketSelection;
