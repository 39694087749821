import React, { useEffect, useState } from "react";
import Qrcode from "../../assets/Qr.png";
import Navbar from "../Navbar/Navbar";
import Footer from "../../page/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { EventGetItems } from "../../redux/createEvent/createEventAction/EventDetailAction";

const Ticket = () => {
  const [Qr, setQr] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, data, error } = useSelector((state) => state.eventItems);
  console.log(data, "hello");
  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);

  const handleclick = () => {
    setQr(!Qr);
  };
  return (
    <div className="flex flex-col gap-4 ">
      <Navbar />
      <div className="w-full">
        <div className="mb-4 flex flex-col gap-4 w-[90vw] justify-center p-5">
          <p className="font-poppins text-xl tracking-wider font-semibold">
            Your Tickets
          </p>
          <div className="shadow-md p-2 w-[400px]">
            <img src={data?.thumbnail_path} alt="image" />
            <p className="font-poppins text-base tracking-wider leading-8">
              {data?.event_name}
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Purchase date:{data?.start_date}
            </p>
            <p className="font-poppins text-base tracking-wider leading-8 font-semibold">
              Sajjan Raj Vaidya Live in London
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Purchase date: Wednesday, July 17, 2024
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Event day: Sat, July 27th 7 PM Onwards
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Venue: {data?.venue}
            </p>
            <p className="font-poppins text-base tracking-wider leading-8 font-semibold">
              Ticket Details
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Price {}
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Paid: Yes
            </p>
            <p className="font-poppins text-base tracking-wider leading-8">
              Order Reference: SAJ234j2afd
            </p>
            <p
              className="font-poppins text-base tracking-wider leading-8 justify-center flex bg-[#0875E1] text-white p-2 rounded-md"
              onClick={handleclick}
            >
              View QR Code
            </p>
          </div>
          {Qr && (
            <div className="absolute flex flex-col justify-center z-20 bg-white shadow-md left-[60px] p-2">
              <img src={Qrcode} alt="image" className="w-[250px] h-[250px]" />
              <p className="flex justify-center font-poppins text-base tracking-wider ">
                Order Reference: SAJ234j2afd
              </p>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Ticket;
