
import { GET_VENUE_DETAIL_FAILURE, GET_VENUE_DETAIL_REQUEST, GET_VENUE_DETAIL_SUCCESS } from "../VenueConstant/VenueDetailConstant";

const initialState = {
    venueDetail: null,
    loading: false,
    error: null,
};

const venueDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VENUE_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_VENUE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                venueDetail: action.payload,
            };
        case GET_VENUE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default venueDetailReducer;
