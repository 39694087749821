import {VENUE_UPDATE_EDIT_FAILURE, VENUE_UPDATE_EDIT_REQUEST, VENUE_UPDATE_EDIT_SUCCESS } from "../VenueConstant/VenueUpdataConstant";


const initialState = {
  loading: false,
  venueEdit: null,
  error: null,
};

const venueUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENUE_UPDATE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VENUE_UPDATE_EDIT_SUCCESS:
        return{
            ...state,
            loading:false,
            venueEdit:action.payload
        }
    case VENUE_UPDATE_EDIT_FAILURE:
        return{
            ...state,
            loading:false,
            error:action.payload
        }
    default:
        return state;
  }

};

export default venueUpdateReducer