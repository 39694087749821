import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReviewAndPayment = ({ ticketDetails, handleConfirmPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  console.log(ticketDetails)

  const handlePayment = async () => {
    setLoading(true);

    try {
      const accessToken = localStorage.getItem("access");

      if (!accessToken) {
        setErrorMessage("Authentication accessToken not found. Please log in.");
        setLoading(false);
        return;
      }

      if (!stripe || !elements) {
        setErrorMessage("Stripe has not been properly initialized.");
        setLoading(false);
        return;
      }

      const formData = {
        event: ticketDetails.event,
        ticket: ticketDetails.event,
        ticket_quantity: ticketDetails.ticket_quantity,
        sub_total: ticketDetails.sub_total,
        total: ticketDetails.total,
      };

      const { data } = await axios.post("https://api.eventfullsunday.co.uk/api/ticket-purchase/", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const clientSecret = data.client_secret;

      if (!clientSecret) {
        setErrorMessage("Payment could not be confirmed. Please try again.");
        setLoading(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);
      const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: ticketDetails.event_name,
          },
        },
      });

      if (paymentError) {
        setErrorMessage(paymentError.message);
        setLoading(false);
        return;
      }

      if (paymentIntent && paymentIntent.status === "succeeded") {
        toast.success("Payment confirmed! Your booking is successful.");
        handleConfirmPayment();
      } else {
        setErrorMessage("Payment could not be confirmed. Please try again.");
      }
    } catch (error) {
      console.error("Error confirming payment", error);
      setErrorMessage("Payment failed. Please try again.");
    }

    setLoading(false);
  };

  // Custom options for CardElement without requiring postal code
  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
    hidePostalCode: true, // Hide the postal code field
  };

  return (
    <div className="shadow-lg bg-gray-100 p-6 rounded-lg">
      <h2 className="font-poppins text-lg md:text-xl font-semibold text-center text-gray-800">
        Review and Payment
      </h2>
      <div className="mt-6">
        <p><strong>Event Name:</strong> {ticketDetails.event_name}</p>
        <p><strong>Venue:</strong> {ticketDetails.venue}</p>
        <p><strong>Time:</strong> {ticketDetails.time}</p>
        <p><strong>Address:</strong> {ticketDetails.address}</p>
        <p><strong>Event ID:</strong> {ticketDetails.event}</p>
        <p><strong>Ticket Type:</strong> {ticketDetails.ticket}</p>
        <p><strong>Price per Ticket:</strong> ${ticketDetails.price.toFixed(2)}</p>
        <p><strong>Ticket Quantity:</strong> {ticketDetails.ticket_quantity}</p>
        <p><strong>Subtotal:</strong> ${ticketDetails.sub_total.toFixed(2)}</p>
        <p><strong>Total:</strong> ${ticketDetails.total.toFixed(2)}</p>
      </div>

      <div className="mt-4">
        <CardElement options={cardElementOptions} />
      </div>

      {errorMessage && (
        <div className="text-red-500 mt-4 text-center">{errorMessage}</div>
      )}

      <div className="flex justify-center mt-6">
        <button
          className="bg-blue-600 text-white py-2 px-4 rounded-lg"
          onClick={handlePayment}
          disabled={!stripe || loading}
        >
          {loading ? "Processing..." : "Confirm Payment"}
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ReviewAndPayment;
